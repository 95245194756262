import React from "react";
import { graphql, Link } from "gatsby";

import { openPopupWidget } from "react-calendly";
// import { List, MicroStar } from "@dadoagency/gatsby-plugin-trustpilot-widget";

import Layout from "../components/Layout";
// import Hero from "../components/Hero";
import { Hero, Section } from "../components/bulma";
// import Slideshow from '../components/Slideshow';
import Blurbs from "../components/Blurbs";
import Pitch from "../components/Pitch";
import ContentSection from "../components/ContentSection";
// import FeaturedBlog from "../components/FeaturedBlog";
import Faq from "../components/Faq";
import CTA from "../components/CTA";
// import Tags from "../components/Tags";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SEO from "../components/SEO/SEO";

import trustpilot from "../img/trustpilot-45stars.png";
import stars5 from "../img/stars-5.svg";
import phones from "../img/phones.png";

import reviews from "../config/reviews";

const url = "https://calendly.com/fastlabs/free-consultation";

export default function Reviews({}) {
  return (
    <Layout>
      <Section>
        <p className="title is-1 has-text-centered m-6">What our clients say</p>

        <div className="columns is-multiline mt-6">
          {reviews.map(review => (
            <div className="column is-3">
              <div className="notification">
                <p className="title is-4">
                  {review.title} <img src={stars5} style={{ height: 20 }} />
                </p>
                <p className="subtitle is-6">{review.text}</p>

                <p className="title is-6 mt-1">{review.author}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      <Section>
        <CTA />
      </Section>
    </Layout>
  );
}
